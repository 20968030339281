.hidden-form {
  display: none;
}

.border{border: 1px solid;}
.mb20{margin-bottom: 20px;}
.mb5{margin-bottom: 5px;}
.p5{padding: 5px;}
.progress_wrapper{max-width: 250px; position: fixed; bottom: 0; right: 25px;}
.progress_wrapper .progress-bar{border-radius: 20px;}
.progress_wrapper .bar_dot{width: 15px; height: 15px; background-color: black; position: absolute; top: -2.5px; border-radius: 50%;  -webkit-transition: all 0.4s; transition: all 0.4s;}
.progress_wrapper .progress{height: 10px; position: relative; overflow: visible; border-radius: 20px;}
.progress.outer, .r_title{display: inline-block; margin-bottom: 0;}
.progress.outer{min-width: 75%;}
.dropdown-messages{width: 300px;}
.dropdown-messages > li > a{white-space: normal;}
.nav.navbar-nav li>.cgreen{color: #6dc731;}
.search_wrapper{margin-top: 8px;display: inline-block;position: relative;}
#search {
  display: inline-block;
  position:relative;
  width: 34px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  background-color: #fff;
  padding: 12px 20px 12px 32px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  cursor: pointer;

}
#search:focus {
  width: 100%;
  outline: 0;
  background-image: none;
  box-shadow: none;
  cursor: text;
}
.nav .form-control-feedback{
  left: 0;
}
.banner{
  margin-top: -20px;
  height: 400px;
  background: url('../img/bgdash.jpg') center center no-repeat scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.side_menu li{padding: 0;}
.side_menu li a{padding: 10px 15px;display: block;color: #000;}
.side_menu li a .fa{margin-left: 5px;font-size: 15px;}
.side_menu li a:hover{background-color: #337ab7;color: #fff;text-decoration: none;}
.mwidth{width: 1px;}
.comment-row{
  margin: 20px 0 0;
}
.sources-block {
  margin-top: 20px;
}
.navbar-brand {
  padding: 0 15px;
}
.navbar-brand>img {
  height: 100%;
  padding: 5px;
  width: auto;
}
@import "bootstrap/bootstrap";
@import "//fonts.googleapis.com/css?family=Roboto:400,300";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
  font-weight: 300;
}

@import "styles.less";

@import "fontawesome/font-awesome";
@import "others/dataTables.bootstrap.less";